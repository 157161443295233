// Quill

.add-new-post__editor {
  .ql-container,
  .ql-editor {
    height: $ql-height;
    overflow:scroll;
  }
}


.add-terms__editor {
  .ql-container,
  .ql-editor {
    height: $ql-height - 100;
    overflow:scroll;
  }
}