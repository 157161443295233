.media-queries{
    position:fixed;
    bottom:0;
    left:0;
    z-index:9999;

    .media-querie{
        padding:0.2rem 1rem;
        background-color:orange;
    }
}

.font-size-xxl{
    font-size: $font-size-xxl !important;
}

.font-size-xl{
    font-size: $font-size-xl !important;
}

.font-size-lg{
    font-size: $font-size-lg !important;
}

.font-size-md{
    font-size: $font-size-md !important;
}

.font-size-sm{
    font-size: $font-size-sm !important;
}

.font-size-xs{
    font-size: $font-size-xs !important;
}

img{
    max-width:100%;
}

.medias-abus{
    width:100%;
}

.btn-primary, .btn-primary:hover, .btn-outline-primary:hover{
    color:$light;
}

.-pageSizeOptions{
    position: absolute;
    top: -35px;
    left: 0;
    &:before{
        content:'Voir '
    }
}

.waiting {
    animation: rotation 2s infinite linear;
}

.user-avatar {
    background-color: theme-color(primary);
    color:$white;
    padding: 0.6rem;
    font-size: 1rem;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.alert {
    margin-bottom: 0;
    position: fixed;
    top: 10px;
    width: 300px;
    z-index: 1075;
    right: 10px;
}



.alert-close{
    position: absolute;
    top: -6px;
    right: 6px;
    font-size: 1.3rem;
    cursor: pointer;
}

.auth-form{
    min-width: auto!important;
}

.web-navbar{
    background-color: darken(theme-color(primary), 10);

    .nav-link{
        font-size: 0.9rem;
    }
}

.intro{
    background: url('../images/intro.jpg') center no-repeat;
    background-size:100%;
    @include media-breakpoint-only(lg) {
        background-size:160%;
    }
    @include media-breakpoint-only(md) {
        background-size:200%;
    }
    @include media-breakpoint-only(sm) {
        background-size:280%;
    }
    @include media-breakpoint-only(xs) {
        background-size:450%;
    }

    color:$white;

    .main-title{
        font-family: $font-family-web;
        font-size:2.5rem;
        color:$white;
    }

    .row{
        background-color: rgba(26,26,26,0.72);
        padding-top:12rem;
        padding-bottom:12rem;
    }
}

.intro, .who-can-use, .why-use-it, .how-use-it, .choose-pack{
    font-family: $font-family-web;
    
    a{
        font-family: $font-family-web;
    }

    .icon{
        height:130px;
    }
}

.book{
    .card-body{
        padding:1rem;
    }
    .book-buttons{
        background-color: white;
        padding:1rem;
        width:270px;
    }
}

.cursor-grab{
    cursor:grab;
}

.cursor-pointer{
    cursor:pointer;
}

.cursor-move{
    cursor:move;
}

.selected{
    border:2px solid theme-color(primary);
}

.overflow-scroll{
    overflow: scroll;
}

.overflow-hidden{
    overflow: hidden;
}

.height-auto{
    height:auto!important;
}

.height-500{
    max-height:500px;
}

.height-400{
    max-height:400px;
}

.fixed-height-small{
    height:40px;
}

.thumbnail{
    height:150px;
    background-color: rgb(241, 241, 241);
    overflow:hidden;

    img{
        width:100%;
    }
}

.delete-img{
    position: absolute;
    width:100%;
    bottom:0;
    left:0;
    background-color: #fff;
    opacity: 0.5;
    padding:0.2rem;
    display: none;
    text-align: center;
    &:hover{
        opacity: 1;
        color:theme-color(primary)!important;
    }
}

.thumbnail{
    &:hover .delete-img{
        display: block;
    }
}

.font-family-montserrat{
    font-family: $font-family-web;

    & .font-weight-bold{
        font-weight: 900!important;
    }
}

.box{
    background-color: $dark-blue;
    height:470px;
    width:100%;
    cursor: pointer;
    
    .verso{
        display: none;
    }

    &:hover{
        background-color: $white;

        .verso{
            display: flex;
            height:100%;
            width:100%;
            flex-direction: column;
            justify-content: flex-end;
            
            .header, .body{
                color: theme-color(info);
            }

            .header{
                .title{
                    color: theme-color(info);
                    font-weight: bold;
                }
            }

            .body{
                .price-per-pdf{
                    font-style: italic;
                    font-size: 0.8rem;
                    display: block;
                    color: theme-color(secondary);
                }
            }

           
            .footer{
                .price{
                    background-color: theme-color(primary);
                    color:$white;
                    font-size: $font-size-lg;
                    font-weight: bold;
                    width:100%;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                }
            }
        }

        .recto{
            display: none;
        }
    }
}

.divider{
    display: block;
    margin:0 auto;
    width:50%;
    height:3px;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.choose-pack{
    background: url('../images/people-around-desk.jpg') center no-repeat;
    .bg-image{
        background-color: rgba(theme-color(info),0.9);
    }
    background-size:100%;
}

.line-height-1{
    line-height: 1;
}

.bullet{
    position: absolute;
    left: -3.5rem;
    top: -0.5rem;
    width:45px;
    height:45px;
    border-radius: 50%;
    text-align:center;
    padding-top:0.5rem;
}

.sticky-top{
    z-index:1060!important;
}

.terms{
    height: 300px;
    &-min{
        height: 200px;
    }
    overflow: scroll;

    &::-webkit-scrollbar {
        -webkit-appearance: auto;
        -moz-appearance: auto;
        appearance: auto;
    }
}

.line-through{
    text-decoration: line-through;   
}

.col-xl-14{
    flex: 0 0 116.666667%;
    max-width: 116.666667%;
}

.col-xl-16{
    flex: 0 0 125%;
    max-width: 125%;
}

.text-underline{
    text-decoration: underline;
}

.opacity-1{
    opacity:1!important;
}