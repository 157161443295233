.area{
    position:absolute;
    opacity:0.3;
    &:hover{
        background-color: $black;
        opacity:0.5
    }
}

.set-area{
    position: absolute;
    box-sizing: border-box;
    background-color: rgba(114, 111, 250, 0.3);
    &--active, &:hover{
        border: 1px dashed blue;
        z-index:999;
    }
}