//
// Fonts
//
$font-size-base: 1rem;

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

$font-family-system-first: 'Lato', sans-serif;
$font-family-web: 'Montserrat', sans-serif!important;

$font-size-xs: ($font-size-base * 0.8);
$font-size-sm: ($font-size-base * 1);
$font-size-md: ($font-size-base * 1.2);
$font-size-lg: ($font-size-base * 1.5);
$font-size-xl: ($font-size-base * 2.5);
$font-size-xxl: ($font-size-base * 6);

$black: #000000;

// Colors
$light: #f5f6f8;
$border-color: #e1e5eb;
$theme-colors: (
  "primary": #d0be88,
  "danger": #ff0022,
  "info": rgba(37, 25, 49, 0.9),
  "light": $light
);
$dark-blue: #1b0144;


// Cards
$card-cap-bg: #fff;


// Typography & Fonts

// Disable Google Fonts imports
$enable-fonts-import: false;

// Use the System UI font instead of Poppins.
$font-family-poppins-first:  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

$headings-color: #3D5170;


// Body
$body-background: #F5F6F8;


// Buttons
$btn-font-size: 0.75rem;
$btn-font-weight: 400;
$btn-border-radius: 0.25rem;
$btn-padding-y: 0.5625rem;
$btn-padding-x: 1rem;

$btn-font-size-sm: 0.6875rem;
$btn-padding-y-sm: 0.4286rem;
$btn-padding-x-sm: 0.875rem;

$btn-border-radius-sm: $btn-border-radius;


// Inputs
$input-btn-padding-y:       0.4375rem;
$input-btn-padding-x:       0.75rem;

$input-group-padding-y: 0.75rem;
$input-group-padding-x: 0.625rem;


// Forms
$input-font-size: 0.8125rem;

$input-padding-y-sm: 0.4286rem;
$input-padding-x-sm: 0.8125rem;

$input-padding-y-lg: 0.8125rem;
$input-padding-x-lg: 0.8125rem;
$input-border-radius-lg: 0.375rem;

$custom-select-line-height: 1.5;

$input-padding-x-sm: 0.625rem;

$input-border-radius: 0.25rem;
$input-border-radius-sm: $input-border-radius;

$custom-select-border-radius: $input-border-radius;

$custom-file-height: calc(2.0625rem + 2px);
$custom-file-border-radius: $input-border-radius;

// Navs
$nav-link-padding-x: 0.625rem;


// Navbar brand
$navbar-brand-font-size: 0.9rem;

// Dropdowns
$dropdown-padding-y: 10px;


$popover-arrow-width: 10px;


$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;


$grid-breakpoints: (
  xs: 0,
  sm: 640px,
  md: 1024px,
  lg: 1200px,
  xl: 1440px
)