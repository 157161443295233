// Range DatePicker

.date-range {
  .react-datepicker-wrapper {
    display: $rdp-wrapper-display;
    flex: $rdp-wrapper-flex;
    max-width: $rdp-wrapper-max-width;
  }

  .react-datepicker__input-container {
    width: $rdp-input-container-width;
  }

  .react-datepicker__triangle{
    border-bottom-color: $white!important;;
  }

  .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected{
    background:theme-color(primary)
  }
}

.react-datepicker__day--in-range{
  color:$white!important;
}

@media (max-width: 575px) {
  .date-range .react-datepicker-wrapper {
    max-width: $rdp-wrapper-mobile-max-width;
  }
}

